import { Button, DateBox, Popup } from 'devextreme-react';
import moment from 'moment';
import React, { useState } from 'react';

const DialogPopup = ({title, onClick}) => {

    const now = new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0);
    const [open, setOpen] = useState(true);

    const [date, setDate] = useState({
        desde: now,
        hasta: now
    });  

    const onHiding = (e) => {
        setOpen(false);
    }

    const onClicked = e => {
        onClick(date.desde, date.hasta);
    }

    return (
        <div>
             <Popup
                width={300}
                height={300}
                title={title}       
                visible={open}
                onHiding={onHiding}
                className="bg-fbfbfb"             
            >   
                <label>Desde</label>
                <DateBox defaultValue={date.desde} onValueChanged={e => setDate({...date, desde: e.value})}></DateBox>
                <label>Hasta</label>
                <DateBox defaultValue={date.hasta} onValueChanged={e => setDate({...date, hasta: e.value})}></DateBox>
                <br/>
                <Button text="Aceptar" onClick={onClicked}></Button>
            </Popup>
        </div>
    );
}

export default DialogPopup;
