import { dialogDefault, OPEN_DIALOG_RECIBO, CLOSE_DIALOG_RECIBO } from "../consts";

const mydialog = {...dialogDefault}

export const openDialogRecibo = id => ({
    type: OPEN_DIALOG_RECIBO,
    payload: { open : true, id : id }
});

export const closeDialogRecibo = () => ({
    type: CLOSE_DIALOG_RECIBO,
    payload: {...dialogDefault}
});

export default function dialogReciboReducer(state = mydialog, { type, payload }) {
    const actions = {
        [OPEN_DIALOG_RECIBO] : () => ({...state, ...payload }),
        [CLOSE_DIALOG_RECIBO] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}