import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule, EmptyItem} from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { closeDialogRecibo } from '../../store/dialogRecibo/dialogReciboReducer';
import DataSource from "devextreme/data/data_source";
import { createStore, createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate } from '../../data/app';
import { DataGrid, Button } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid } from 'devextreme-react/data-grid';
import uri from '../../utils/uri';
import toCapital, { cellRender } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault } from '../../data/defaultObjects';
import BillByClient from '../../components/bill/BillByClient';
import useProducts from '../../hooks/useProducts';

const Nuevo = (props) => {

    const { dialogRecibo, user } = useSelector(store => store);

    const { products, isLoading } = useProducts({ areaId : user.areaId});
    const [ saving, setSaving ] = useState(false);
    const [ bill, setBill ] = useState({...billDefault});


    const dispatch = useDispatch(); 

    const closeDialog = ( load ) => {       

        dispatch(closeDialogRecibo());
        if (load) {
            let { onSave } = props;
            onSave();      
        }        
    }

    const onHiding = ({ load }) => {       
        closeDialog(load);        
    }   

    const onCellPrepared = e => {

        const cells = ['quantity', 'quantityRequest']
            
        if (e.rowType == 'data' && e.column.allowEditing) {
            if(cells.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');           
        }
    
    }

    useEffect(() => { 

        if(dialogRecibo.id > 0)
            http(uri.bill.getById(dialogRecibo.id)).asGet().then(resp => {

                setBill({...resp});

            });

    }, [dialogRecibo.id]);

    return (
        <div>
            <Popup
                width={1050}
                height={580}
                title={`Recibos`}
                onHiding={onHiding}
                visible={dialogRecibo.open}   
                className="bg-fbfbfb"             
            >
                <BillByClient open={dialogRecibo.open}/>                                       
                
            </Popup>
        </div>
    );
}

export default Nuevo;
