
import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { store } from '../../services/store';
import uri from '../../utils/uri';
import { createStore } from '../../utils/proxy';
import { Column, Editing, Lookup, Pager, Paging } from 'devextreme-react/data-grid';
import { cellRender, dataFormatId, formatId } from '../../utils/common';
import { formatDate } from '../../data/app';
import { onCellPreparedReceipt } from './CellPrepared';
import BlockHeader from '../shared/BlockHeader';
import urlReport from '../../services/reportServices';

const DetailTemplate = props => {
     
    const { onSave } = props;

    const { id } = props.data?.data;
    const title = "Recibos generados";

    const refGrid = useRef();

    const addMenuItems =(e) => {
        
        if (e.target == "content") {
            
            // e.items can be undefined
            if (!e.items) e.items = [];
 
            // Add a custom menu item
            if(e.rowIndex >= 0)
                e.items.push({

                    text: `Re-imprimir recibo`,
                    icon : 'print',
                    onItemClick: () => {
                        const report = urlReport();
                        report.print(`${report.recibo(e.row.data.id)}`);
                    }
                    
                },{

                    text: `Anular recibo`,
                    icon : 'remove',
                    onItemClick: () => refGrid.current.instance.deleteRow(e.rowIndex),                    
                    color : 'red'
                });
           
        }
    }

    const onRowRemoved = (e) => {
        onSave();
    }

    return (<>
        <BlockHeader title={title} />
               
        <DataGrid
            ref={refGrid}
            id="gridReceipt"
            selection={{ mode: 'single' }}
            dataSource={store({ uri: { get : uri.receivables.byBill(id), remove : uri.receivables.remove } })}
            showBorders={true}
            showRowLines={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            onContextMenuPreparing={addMenuItems}
            onRowRemoved={onRowRemoved}
            // onRowPrepared={onRowPrepared}
            // onToolbarPreparing={onToolbarPreparing}
            onCellPrepared={onCellPreparedReceipt}           
        >
            <Paging defaultPageSize={20} />
            <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
            />
            <Column dataField="id" caption="Recibo #" width={90} cellRender={dataFormatId} />
            <Column dataField="createAt" caption="Fecha" dataType='date' format={formatDate} width={120} />
            <Column dataField="amount" caption="Pagado"  width={100} cellRender={cellRender()} />
            <Column dataField="balance" caption="Pendiente"  width={100} cellRender={cellRender()} />
            <Column dataField="createBy" caption='Creado por' width={100} />
           
            <Editing
                mode="popup"
                allowDeleting={true}
                useIcons={true}
            ></Editing>
        </DataGrid>
    </>);
};




function getProviders(data, providers) {

    if (data.itemProviders != null) {
        return providers.filter(x => data.itemProviders.some(p => p.providerId == x.id));
    }
    return [];
}

export default DetailTemplate;
