import { dialogDefault, DIALOG_BILL } from "../consts";

const mydialog = {...dialogDefault}


export const billDialogAction = ({ open = false, id = 0, clientId = 0  }) => ({
    type: DIALOG_BILL,
    payload : { open, id, clientId  }
});

export default function billDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_BILL] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

