import { dialogDefault, OPEN_DIALOG, CLOSE_DIALOG } from "../consts";

const mydialog = { ...dialogDefault }

export const openDialog = payload => ({ type: OPEN_DIALOG, payload});
export const closeDialog = () => ({ type: CLOSE_DIALOG });

export default function customDialogReducer(state = mydialog, { type, payload }) {
    const actions = {
        [OPEN_DIALOG] : () => ({...state, open : true, ...payload }),
        [CLOSE_DIALOG] : () => ({...mydialog })
    }

    return actions[type]?.call() || state;
}