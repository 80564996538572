import React, { useEffect, useState } from 'react';
import { Box, DataGrid, DateBox, DropDownBox, SelectBox } from "devextreme-react";
import { dataSourceSelect } from '../../services/store';
import uri from '../../utils/uri';
import Bills from '../../views/bills/Bills';
import BillCxC from './BillCxC';

const BillByClient = props => {
    
    const { open } = props;

    const [clientId, setClientId] = useState(0);

    const onValueChanged = e => setClientId(e.value);    

    useEffect(() => {
        setClientId(0);       
    }, [open]);

    return (
        <div>
            <div className="ml-10">
                <label>Seleccione el cliente:</label>
                <SelectBox 
                    value={clientId}
                    searchEnabled={true}
                    placeholder="Selecciona un cliente"
                    showClearButton={true} valueExpr="id" displayExpr="fullName" 
                    onValueChanged={onValueChanged}     
                    dataSource={dataSourceSelect(uri.clients.asCatalog, uri.clients.getById(clientId))}
                />
                <BillCxC clientId={clientId}/>
            </div>
        </div>
    );
}

export default BillByClient;
