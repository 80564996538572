import React, { useRef, useState } from 'react';
import { DropDownBox, DataGrid } from 'devextreme-react';
import { Column, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import DataSource from "devextreme/data/data_source";
import http from "../../utils/http";
import uri from "../../utils/uri";

const dataSource = () => new DataSource({
    load: (loadOptions) => {

        let params = {};
        params.skip = loadOptions.skip || 0;
        params.take = loadOptions.take || 10;

        if(loadOptions.searchValue)
            params.name = loadOptions.searchValue  ;

        return http(uri.clients.asCatalog)
        .asGet(params).then(x => x.items);
        
    },
    byKey: id => http(uri.clients.getById(id)).asGet(),
    paginate : true,
    pageSize: 10
})

export const editorOptionsDropDown = {
    
    dataSource: dataSource(),
    valueExpr:"id",
    displayExpr: item => item ? `${item.id} - ${item.name} ${item.lastName}` : '',
    searchEnabled: true
}



export const DropDownBoxClient = props => {

    const dropDownBoxRef = useRef();

    const { onValueChanged } = props;
    const [value, setValue] = useState(0);

    const contentRender = (params) => {

        const onSelectionChanged =e =>{

            if (e.selectedRowKeys.length > 0) {   
                dropDownBoxRef.current.instance.close(); 
                setValue(e.selectedRowKeys[0].id);    
                onValueChanged(e.selectedRowKeys[0].id)
            }           
    
        }
       
        return (
            <DataGrid
              dataSource={dataSource()}
              hoverStateEnabled={true}
              remoteOperations={{
                paging: true,
                filtering: true
              }}
              selectedRowKeys={[value]}
              onSelectionChanged={onSelectionChanged}
              height="100%">
                <Selection mode="single" />
                <Scrolling mode="infinite" />
                <Paging enabled={true} pageSize={10} />      
                <Column width={80} dataField="id" caption="Codigo" />        
                <Column width={150} dataField="identification" caption="Identificacion" />        
                <Column dataField="fullName" caption="Nombre" />        
                <Column width={150} dataField="fileNumber" caption="# Expediente" />        
            </DataGrid>
        );
    }

    return(        
        <DropDownBox
            width={750}
            ref={dropDownBoxRef}
            value={[value]}
            valueExpr="id"
            displayExpr={item => item ? `${item.name} ${item.lastName}` : ''}
            placeholder="Seleccione un cliente"               
            dataSource={dataSource()}
            // onValueChanged={onValueChanged}  
            contentRender={contentRender}          
        />
    )
}