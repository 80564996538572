
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import urlReport from '../../../services/reportServices';
import DialogPopup from './DialogPopup';

const ProductsInfo = () => {

    const { user } = useSelector(store => store);
   
    const getReport = (desde, hasta) => {

        const report = urlReport();
        report.print(`${report.products(moment(desde).format('YYYY-MM-DD'), moment(hasta).format('YYYY-MM-DD'), user.username)}`);

    } 

    return (
        <div>
            <DialogPopup onClick={getReport} title='Productos vendidos'></DialogPopup>
        </div>
    );
}

export default ProductsInfo;
