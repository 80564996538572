export const onCellPreparedReceipt = e => {    

    const cells = ['payoff']

    if (e.rowType == 'data' && e.column.allowEditing) {
        if (cells.includes(e.column.dataField))
            if (e.row.data.payoff)
                e.cellElement.classList.add('pagada');
            else
                e.cellElement.classList.add('pendiente');

        if (e.column.dataField == 'id' && e.element.id == 'grid')
            e.cellElement.classList.add('col-id-factura');
        if (e.column.dataField == 'id' && e.element.id == 'gridReceipt')
            e.cellElement.classList.add('col-id-receipt');

        if (e.column.dataField == 'billId')
            e.cellElement.classList.add('col-id-factura');
      

        if (e.column.dataField == 'total')
            e.cellElement.classList.add('col-id-total');
        if (e.column.dataField == 'amount')
            e.cellElement.classList.add('col-id-pagado');
        if (e.column.dataField == 'balance')
            e.cellElement.classList.add('col-id-pendiente');
    }

}

