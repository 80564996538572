import React from 'react';
import { Button } from 'devextreme-react/button';

const CustomButton = ({
        text, 
        icon, 
        onClick, 
        type='default',
        stylingMode="contained",
        className ='btn-header',
        visible=true,
        disabled=false
    }) => {

    return (             
            
            <Button
                text={text}
                type={type}
                icon={icon}
                stylingMode={stylingMode}
                className={className}
                onClick={onClick}
                visible={visible}
                disabled={disabled}
            />    
    );
}

export default CustomButton;
